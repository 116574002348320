/*
 * @Author: 陈剑伟
 * @Date: 2020-05-19 14:10:56
 * @LastEditTime: 2020-09-30 10:30:15
 * @LastEditors: 林娴
 * @Description: In User Settings Edit
 * @FilePath: \onesports-cloud-view-new\src\views\venueMana\course\course.js
 */
import pageClass from '@/unit/pageClass'

class searchFrom extends pageClass {
  constructor(type) {
    super()
    if (type === 'form') {
      this.searchValue = ''
      // this.publishStatus = ''
      // this.top = ''
      this.contentType = ''
    }
  }
}
export default searchFrom
