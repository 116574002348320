<template>
  <div class="dialog-container weekBoxs">
    <el-dialog
      :title="`${isEdit ? $t('edit') : $t('add')}`"
      :visible.sync="show"
      @open="openDialog"
      :before-close="closeDialog"
      center
      :close-on-click-modal="false"
      width="700px">
      <el-form ref="form" :model="form" label-width="120px" :rules="rules">
        <el-row>
          <el-col :span="24">
            <el-form-item label="资讯类型" prop="type" label-width="120px">
              <el-select v-model="form.type" clearable="" style="width:100%">
                <el-option v-for="(item, index) in DROPDOWNBOX.WB_NEWS_NOTICE_TYPE" :key="`${item.label}_${index}`" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="文章标题" prop="title">
              <el-input clearable v-model="form.title" placeholder="最多20个字" :maxlength="20"></el-input>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="24">
            <el-form-item label="文章副题" prop="subtitle">
              <el-input clearable type="textarea" placeholder="最多50个字" :maxlength="50" :rows="4" v-model="form.subtitle" ></el-input>
            </el-form-item>
          </el-col> -->
          <el-col :span="24">
              <el-form-item  label="资讯封面图片" prop="cover">
                <el-upload
                  class="upload"
                  :show-file-list="false"
                  :with-credentials="true"
                  :action="apis.ImageUpload"
                  :httpRequest="uploadHttpDefault"
                  name="file"
                  accept=".png,.jpg"
                  :on-success="uploadSuccess"
                  :on-error="uploadError">
                    <img v-if="form.cover" class="image-upload" :src="form.cover" alt="" srcset="" width="78" height="78">
                    <img v-else class="image-upload" src="@/assets/images/upload_image.png" alt="" srcset="">
                    <div class="upload-images-tip">
                      图片尺寸不小于320*174px，图片格式png/jpg
                    </div>
                </el-upload>
              </el-form-item>
            </el-col>
          <el-col :span="24"><div class="course-text">资讯详情内容（图片）</div></el-col>
          <el-col :span="24">
            <el-form-item prop="content" label-width="0">
              <div class="edit_container">
                <!-- <quill-editor @change="onEditChange" v-model="form.detail" ref="myQuillEditor" class="ql-editor editer" :options="editorOption" @ready="onEditorReady($event)">
                </quill-editor> -->
                <quill-edit :detail="form.content" @change="onEditChange" :qnLocation="apis.ImageUpload">
                </quill-edit>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template slot="footer">
        <el-button class="footer-confirm" @click="onPreview">预览</el-button>
        <el-button class="footer-confirm" @click="sureEvent">{{$t('sure')}}</el-button>
        <!-- 如果没特别需求，统一使用mixin里面的关闭方法 -->
        <el-button class="footer-cancel" @click="closeDialog">{{$t('cancel')}}</el-button>
      </template>
    </el-dialog>
     <!-- 图片预览 -->
    <preview-image ref="previewImage" :show="previewImage.visible" @closeDialog="() => {this.previewImage.visible=false}"></preview-image>
  </div>
</template>
<script>
import { mapState } from 'vuex'
// import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import mixin from '@/mixins/dialogMixin'
import AddDialogClass from './addDialogClass'
import apis from '@/apis'
import previewImage from "../component/preview.vue";
import quillEdit from '@/components/quillEdit.vue'

export default {
  components: {
    quillEdit,
    previewImage,
  },
  mixins: [mixin],
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    isEdit: {
      required: true,
      type: Boolean,
    },
    Language: {
      required: false,
      type: Number,
      default: 0,
    },
  },
  /* eslint-disable */
  created() {
    // this.getCoachInfo()
    console.log("created -> DROPDOWNBOX", this.DROPDOWNBOX.VENUE_APPLY_INFO)
  },
  data() {
    return {
      flag: false,
      form: new AddDialogClass('form'), // 实例化一个表单的变量
      rules: new AddDialogClass('rule', this.Language), // 实例化一个表单的规则
      roleData: [], // 角色树状的值
      typeList: [],
      apis,
      showPic: true,
      coachList: [], //教练集合
      applyInfoList: [], //报名列表集合
      editorOption: {
        placeholder: '请输入...',
        modules: {
          toolbar: [
                    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                    ['blockquote', 'code-block'],

                    [{'header': 1}, {'header': 2}],               // custom button values
                    [{'list': 'ordered'}, {'list': 'bullet'}],
                    [{'script': 'sub'}, {'script': 'super'}],      // superscript/subscript
                    [{'indent': '-1'}, {'indent': '+1'}],          // outdent/indent
                    [{'direction': 'rtl'}],                         // text direction
                    [{'size': ['small', false, 'large', 'huge']}],  // custom dropdown
                    [{'header': [1, 2, 3, 4, 5, 6, false]}],
                    [{'color': []}, {'background': []}],          // dropdown with defaults from theme
                    [{'font': []}],
                    [{'align': []}],
                    ['image', 'formula'] //去除video即可
                ]
        }
      },
      previewImage: {
        visible: false,
        src: "",
      },
      // 结束时间不能大于开始时间
      startTimeOptions: {
        disabledDate: (time) => {
         if (this.form.signUpEndTime !== "") {
            return time.getTime() > new Date(this.form.signUpEndTime).getTime() || time.getTime() < Date.now() - 8.64e7;
          } else {//还没有选择结束时间的时候，让他只能选择今天之后的时间包括今天
            return time.getTime() < Date.now() - 8.64e7
          } 
        },
      },
       endTimeOptions: {
        disabledDate: (time) => {
          if (this.form.signUpStartTime !== "") {
            return time.getTime() < new Date(this.form.signUpStartTime).getTime();
          } else {//还没有选择开始时间的时候，让他只能选择今天之后的时间包括今天
            return time.getTime() < Date.now() - 8.64e7
          }
        },
      },
    }
  },
  computed: {
    ...mapState(['DROPDOWNBOX', "TOKEN"]),
  },
  methods: {
    onEditChange(value) {
      console.log("onEditChange")
      this.form.content = value
      this.$refs.form.validateField("content");
    },
    onApply(value) {
    console.log("onApply -> value", value)
    },
    onPickerChange(value) {
      this.form.startTime = value[0]
      this.form.deadLine = value[1]
    },
    /**
     * @function 文本编辑器
    */
    onEditorReady(editor) {
      console.log(editor)
    },
    onPreview() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.previewImage.visible = true
          this.$nextTick(() => {
            this.$refs['previewImage'].getPreviewData(this.form)
          })
        }
      })
    },
    /**
     * 获取教练集合
     */
    // getCoachInfo() {
    //   this.$http.post(apis.coachInfoList, {
    //     "pageNum": 1,
    //     "pageSize": 100000,
    //   }).then((res) => {
    //     if (res.data.code === 0) {
    //       this.coachList = res.data.rows
    //     }
    //   })
    // },
    removeImage(index) {
      this.form.filesPicturesPath.splice(index, 1)
      this.form.files.splice(index, 1)
    },
    uploadSuccess(res) {
      console.log(res)
      this.form.pictureUrl = res.data.url
      this.form.cover = res.data.path
      console.log('cover', this.form.cover)
      this.$refs.form.validateField("cover");
    },
    uploadError(res) {
      console.log(res)
    },
    /**
     * @function 初始化弹窗
     */
    init() {
      this.$refs.form.resetFields()
      this.form = new AddDialogClass('form')
      console.log('我初始化了')
    },
    /**
     * @function 点击确定按钮，先执行业务，完了调关闭方法，不要写两次关闭方法
     */
    sureEvent() {
      // 这里写业务需求
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.flag) { return }
          this.flag = true
          if (this.isEdit) {
            this.$http.put(apis.websiteInformation, this.form).then((res) => {
              if (res.data.code === 0) {
                // 调用关闭方法，不要再写一次
                this.flag = false
                this.closeDialog()
              } else {
                this.flag = false
              }
            })
          } else {
            this.$http.post(apis.websiteInformation, this.form).then((res) => {
              if (res.data.code === 0) {
                // 调用关闭方法，不要再写一次
                this.flag = false
                this.closeDialog()
              } else {
                this.flag = false
              }
            })
          }
        }
      })
      console.log(`我执行了点确定的业务需求`)
    },
    /**
     * @function 回显的时候获取数据
     * @param {Object} data 要传输给后端的数据
     */
    getDataQuery(data) {
      // 在这里获取回显的数据
      this.$http.get(`${apis.getWebsiteInformationDetail}?id=${data.id}`).then((res) => {
        if (res.data.code === 0) {
          this.form = { ...this.form, ...res.data.data }
        }
      })
    },
  },
}
</script>
<style lang="less" scoped>
.course-text {
  margin-bottom: 20px;
}
.image-upload {
  width: 50px;
  height: 50px;
}
.upload-images-tip {
  color:rgba(168,168,168,1);
  font-size:12px;
  margin-top: -15px;
}
.image-wrap{
  width: 50px;
  height: 50px;
  margin-right: 10px;
  position: relative;
  float: left;
  .images-close {
    position: absolute;
    right: -7px;
    top: -7px;
    width: 14px;
    height: 14px;
    cursor: pointer;
  }
}
</style>
<style lang="less">
@import "../../../../assets/styles/mixin.less";
.edit_container {
  .ql-snow {
    .ql-tooltip {
      left: 10px !important;
    }
  }
}
.weekBoxs {
  .el-checkbox-button {
    margin-right: 5px;
  }
  .ql-editor {
    .ql-editor {
      height: 188px;
    }
  }
  .ql-snow .ql-formats {
    line-height: 24px;
  }
  .course-checkbox {
    .el-checkbox {
      width: 155px !important;
    }
  }
}
</style>
