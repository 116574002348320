/*
 * @Author: genwai
 * @Date: 2020-05-19 14:54:36
 * @LastEditTime: 2020-09-30 12:30:36
 * @LastEditors: 林娴
 * @Description: In User Settings Edit
 * @FilePath: \onesports-cloud-view-new\src\views\venueMana\course\dialog\addDialogClass.js
 */

class searchFrom {
  constructor(type) {
    if (type === 'form') {
      this.title = ''
      this.subtitle = ''
      this.content = ''
      this.cover = ''
      this.pictureUrl = ''
      this.type = ''
      // 无需传给后端
      this.filesPicturesPath = []
    } else if (type === 'rule') {
      this.title = [
        {
          required: true,
          message: '请输入标题',
          trigger: 'change',
        },
      ]
      // this.subtitle = [
      //   {
      //     required: true,
      //     message: '请输入副标题',
      //     trigger: 'change',
      //   },
      // ]
      this.type = [
        {
          required: true,
          message: '请选择资讯类型',
          trigger: 'change',
        },
      ]
      this.content = [
        {
          required: true,
          message: '请输入资讯内容',
          trigger: 'change',
        },
      ]
      this.cover = [
        {
          required: true,
          message: '请选择图片',
          trigger: 'change',
        },
      ]
    }
  }
}
export default searchFrom
